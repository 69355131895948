:root {
  --pbpadding: 3em;
  --pbdoublepadding: 6em;
  --pbtriplepadding: 9em;
}

:root {
  --base-font-size: 17px;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  color: #000000;
  font-size: 17px;
  font-family: "Mont-light", Arial;
  font-variation-settings: "wght" 400;
  background-color: white;
  position: relative;
}

p {
  font-size: var(--base-font-size);
  line-height: 1.65em;
  margin-bottom: 1.25em;
}

strong,
b {
  font-family: "Mont-bold";
  font-variation-settings: "wght" 600;
}

img {
  height: auto;
}

h2 + p,
h3 + p,
h4 + p,
h5 + p,
h5 + ul {
  margin-top: 1rem;
}

p + .keylink {
  margin-top: 1em;
}

p + ul {
  margin-bottom: 2em;
}

a:not(.nav-link, .skiplink, .reset-link), .link {
  color: #000;
  text-decoration: none;
}
a:not(.nav-link, .skiplink, .reset-link):hover, .link:hover {
  text-decoration: underline;
}

article.text-side a, article a {
  text-underline-offset: 0.3em;
}

.reset-link {
  text-decoration: none !important;
  color: black !important;
}
.reset-link:hover {
  text-decoration: underline !important;
  text-underline-offset: 3px;
}

:is(.backto.backto) {
  text-decoration: none;
  opacity: 0.6;
  margin-top: 2rem;
  display: inline-block;
}
:is(.backto.backto) img {
  width: 27px;
  margin-right: 5px;
}
:is(.backto.backto):hover {
  text-decoration: underline;
}

.mysuperflexteststyle {
  background-color: red;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

*:focus:not(:focus-visible) {
  outline: none !important;
}

div.objectfit {
  height: 100%;
}
div.objectfit img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

svg.icon {
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  fill: currentColor;
  position: relative;
  top: 3px;
  margin: -2px 5px 0 0;
}

.skiplink {
  position: absolute;
  left: -9999em;
}
.skiplink:focus {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  font-size: 2rem;
  z-index: 1000;
  background: #000000;
  padding: 1rem 1.8rem;
  border: 4px solid white;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.withflex {
  display: flex;
}

.centred {
  text-align: center;
}

.align-right {
  text-align: right;
}

.gorel {
  position: relative;
}

.noflow {
  overflow: hidden;
}

.rounded-box {
  border-radius: 18px;
  overflow: hidden;
}

.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-blue {
  background-color: #db0132;
  color: #FFF;
}

.bg-grey {
  background-color: #ededed;
}

.bg-greylt {
  background-color: #F5F5F5;
}

.bg-sky {
  background-color: #ededed;
}

.gowhite {
  color: #FFF;
}

.goblue {
  color: #db0132;
}

.bordered-blue {
  border: 0.5px solid #db0132;
}

.with-margin-top {
  margin-top: var(--pbpadding);
}

.with-margin-bottom {
  margin-bottom: var(--pbpadding);
}

.nomargin-top {
  margin-top: 0 !important;
}

.nomargin-bottom {
  margin-bottom: 0 !important;
}

.nopadding-top {
  padding-top: 0 !important;
}

.nopadding-bottom {
  padding-bottom: 0 !important;
}

.noflow {
  overflow: hidden;
}

.smaller-text {
  font-size: 13px;
}
.smaller-text h2 {
  font-size: 26px;
}
.smaller-text p {
  font-size: 13px;
}

@media (max-width: 768px) {
  p + .keylink {
    margin-top: 0.25em;
  }
}
.pb-partners-panel {
  position: relative;
}
.pb-partners-panel h3 + p {
  margin-top: 2.25rem;
}
.pb-partners-panel .copy-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.pb-partners-panel .copy-wrapper .leftcol {
  flex-basis: 50%;
  min-width: 0;
  margin-bottom: 0;
}
.pb-partners-panel .copy-wrapper .leftcol p {
  max-width: 520px;
}
.pb-partners-panel .copy-wrapper .leftcol .whitefade {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #FFF, #FFF);
  width: 200px;
  height: 100%;
  top: 0;
  right: -1px;
  position: absolute;
  z-index: 10;
}
.pb-partners-panel .copy-wrapper .rightcol.offset-image {
  transform: translateY(-45%);
}
.pb-partners-panel .copy-wrapper .rightcol.offset-image img {
  width: 100%;
  max-width: 570px;
  float: left;
}
.pb-partners-panel .logo-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2em;
  align-items: center;
}
.pb-partners-panel .logo-carousel {
  margin: 3em auto;
}
.pb-partners-panel.panel-simple .copy-wrapper {
  gap: 1.5em;
  flex-direction: row;
}
.pb-partners-panel.panel-simple .copy-wrapper .leftcol, .pb-partners-panel.panel-simple .copy-wrapper .rightcol {
  width: 50%;
  flex-basis: 50%;
}
@media (max-width: 920px) {
  .pb-partners-panel.panel-simple .copy-wrapper {
    gap: 2em;
    flex-direction: column;
  }
  .pb-partners-panel.panel-simple .copy-wrapper .leftcol, .pb-partners-panel.panel-simple .copy-wrapper .rightcol {
    width: 100%;
    flex-basis: 100%;
  }
}
@media (max-width: 768px) {
  .pb-partners-panel {
    padding-bottom: 0;
  }
  .pb-partners-panel .copy-wrapper {
    text-align: center;
    flex-direction: column;
    gap: 2;
  }
  .pb-partners-panel .copy-wrapper .leftcol, .pb-partners-panel .copy-wrapper .rightcol {
    flex-basis: 100%;
    max-width: 100%;
  }
  .pb-partners-panel .copy-wrapper .leftcol p {
    margin-left: auto;
    margin-right: auto;
  }
  .pb-partners-panel .copy-wrapper .leftcol .whitefade, .pb-partners-panel .copy-wrapper .leftcol .next-btn-logo-carousel {
    display: none;
  }
  .pb-partners-panel .copy-wrapper .rightcol.offset-image {
    transform: translateY(140px);
  }
  .pb-partners-panel .copy-wrapper .rightcol.offset-image img {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    float: none;
  }
}