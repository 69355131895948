//Global variables
:root {
  //Spacing
  --pbpadding: 3em;
  --pbdoublepadding: 6em;
  --pbtriplepadding: 9em;
}

$font-family: 'Mont-light';
$heading: 'Mont-light';
$font-bold: 'Mont-bold';
$font-book: 'Mont-book';
$font-heavy: 'Mont-heavy';
$font-semi: 'Mont-semi';
$font-clr: #000000;
$atlasred: #db0132;
$grey: #ededed;
$greylt: #F5F5F5;
$greymid: #A5A5A5;
$black: #000;
$white: #FFF;
