@import '../abstracts/variables.scss';
@import '../abstracts/mixins.scss';
@import '../sections/base.scss';

.pb-partners-panel {
    h3 + p {
        margin-top: 2.25rem;
    }
    position: relative;
    .copy-wrapper {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        .leftcol {
            flex-basis: 50%;
            min-width: 0;
            margin-bottom: 0;
            p {
                max-width: 520px;
            }
            .whitefade {
                background-image: linear-gradient(to right, rgba($white, 0%), $white, $white);
                width: 200px;
                height: 100%;
                top: 0;
                right: -1px;
                position: absolute;
                z-index: 10;
            }
        }
        .rightcol {
            &.offset-image {
                transform: translateY(-45%);
                img {
                    width: 100%;
                    max-width: 570px;
                    float: left;
                }
            }
        }
    }
    .logo-grid {
        display: grid;
        grid-template-columns: repeat(3,minmax(0,1fr));
        gap: 2em;
        align-items: center;
    }
    .logo-carousel {
        margin: 3em auto;
    }
    &.panel-simple {
        .copy-wrapper {
            gap: 1.5em;
            flex-direction: row;
            .leftcol, .rightcol {
                width: 50%;
                flex-basis: 50%;
            }
        }
    }
    @include bpr-media-breakpoint(down, lg) {
        &.panel-simple {
            .copy-wrapper {
                gap: 2em;
                flex-direction: column;
                .leftcol, .rightcol {
                    width: 100%;
                    flex-basis: 100%;
                }
            }
        }
    }
    @include bpr-media-breakpoint(down, md) {
        padding-bottom: 0;
        .copy-wrapper {
            text-align: center;
            flex-direction: column;
            gap: 2;
            .leftcol, .rightcol {
                flex-basis: 100%;
                max-width: 100%;
            }
            .leftcol {
                p {
                    margin-left: auto;
                    margin-right: auto;
                }
                .whitefade, .next-btn-logo-carousel {
                    display: none;
                }
            }
            .rightcol {
                &.offset-image {
                    transform: translateY(140px);
                    img {
                        width: 100%;
                        max-width: 320px;
                        margin: 0 auto;
                        float: none;
                    }
                }
            }
        }
    }
}
